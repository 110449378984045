import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import Paragraph from "components/paragraph"
import H1 from "components/h1"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <H1 color="text-black">404: Not Found</H1>
    <Paragraph>
      You just hit a route that doesn&#39;t exist... the sadness.
    </Paragraph>
  </Layout>
)

export default NotFoundPage
